@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ttlStyle_01 {
  margin-bottom: 40 / $design-num-sp * 100vw;
  position: relative;
  > .inner {
    @include baseSpPad;
  }
  h2 {
    text-align: right;
    margin-bottom: 30 / $design-num-sp * 100vw;
    svg {
      display: inline-block;
      width: 215 / $design-num-sp * 100vw;
      height: 43 / $design-num-sp * 100vw;
      fill: get_keycolor(0.3);
      stroke: get_keycolor(0.3);
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      stroke-width: 1;
      opacity: 0;
    }
    &.active svg {
      @include opacity(1);
      animation: ttlEffect 3s ease-in 0s forwards;
    }
  }
  .summary {
    text-align: center;
    letter-spacing: 0.15em;
    .main {
      font-family: $fontMin;
      @include fz_vw(36);
      line-height: 1em;
      font-weight: 600;
      margin-bottom: 20 / $design-num-sp * 100vw;
    }
    .sub {
      @include fz_vw(24);
      line-height: 1em;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_01 {
    margin-bottom: 50px;
    > .inner {
      @include contentWrap;
    }
    h2 {
      margin-bottom: 50px;
      svg {
        width: 430px;
        height: 86px;
      }
    }
    .summary {
      .main {
        font-size: 4.8rem;
        margin-bottom: 30px;
      }
      .sub {
        font-size: 2.6rem;
      }
    }
  }
}
//////////////////////////////////
@keyframes ttlEffect {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
  }
}
///////////////////////////////////
.ttlStyle_02 {
  @extend .ttlStyle_01;
  h2 {
    text-align: left;
    svg {
      width: 105 / $design-num-sp * 100vw;
      height: 32 / $design-num-sp * 100vw;
    }
  }
  .summary {
    .balloon {
      margin-bottom: 18 / $design-num-sp * 100vw;
      svg {
        width: 194 / $design-num-sp * 100vw;
        height: 90 / $design-num-sp * 100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_02 {
    h2 {
      svg {
        width: 211px;
        height: 65px;
      }
    }
    .summary {
      .balloon {
        margin-bottom: 36px;
        svg {
          width: 250px;
          height: 116px;
        }
      }
    }
  }
}
///////////////////////////////////
.ttlStyle_03 {
  @extend .ttlStyle_02;
  h2 {
    text-align: right;
    svg {
      width: 142 / $design-num-sp * 100vw;
      height: 33 / $design-num-sp * 100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_03 {
    h2 {
      svg {
        width: 284px;
        height: 65px;
      }
    }
  }
}
///////////////////////////////////
.ttlStyle_04 {
  @include fz_vw(38);
  line-height: 1em;
  margin-bottom: 30 / $design-num-sp * 100vw;
  color: $key-color;
  font-weight: bold;
  i {
    padding-left: 1em;
    @include fz_vw(26);
    line-height: 1em;
    position: relative;
    top: 0.3em;
  }
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_04 {
    font-size: 4rem;
    margin-bottom: 26px;
    i {
      font-size: 2.6rem;
      top: 0.4em;
    }
  }
}
///////////////////////////////////
.ttlStyle_05 {
  padding-bottom: 100 / $design-num-sp * 100vw;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 160 / $design-num-sp * 100vw 100vw 0 0;
    border-color: $key-gray1 transparent transparent transparent;
    transition: 0.6s;
  }
  .loaded &::before {
    left: 0;
  }
  > .inner {
    position: relative;
    @include baseSpPad;
  }
  .en {
    padding-top: 34 / $design-num-sp * 100vw;
    margin-bottom: 30 / $design-num-sp * 100vw;
    svg {
      fill: get_keycolor(0.3);
      width: 215 / $design-num-sp * 100vw;
      height: 43 / $design-num-sp * 100vw;
      fill: get_keycolor(0.3);
      stroke: get_keycolor(0.3);
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      stroke-width: 1;
      opacity: 0;
    }
    &.style_01 svg {
      width: 105 / $design-num-sp * 100vw;
      height: 32 / $design-num-sp * 100vw;
    }
    &.active svg {
      @include opacity(1);
      animation: ttlEffect 3s ease-in 0s forwards;
    }
  }
  h1 {
    @include fz_vw(50);
    line-height: 1em;
    text-align: center;
    letter-spacing: 0.1em;
    font-family: $fontMin;
    color: $key-color;
  }
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_05 {
    padding-bottom: 160px;
    &::before {
      border-width: 320 / $design-num * 100vw 100vw 0 0;
    }
    > .inner {
      @include contentWrap;
    }
    .en {
      padding-top: 46px;
      margin-bottom: 0;
      svg {
        fill: get_keycolor(0.3);
        width: 430px;
        height: 86px;
      }
      &.style_01 svg {
        width: 211px;
        height: 65px;
      }
      &.style_02 svg {
        width: 284px;
        height: 66px;
      }
    }
    h1 {
      font-size: 6rem;
      position: relative;
      //margin-top: -5px;
    }
  }
}
@media screen and (min-width: $content-width-base) {
  .ttlStyle_05 {
    &::before {
      border-width: 320px 100vw 0 0;
    }
  }
}
///////////////////////////////////
.ttlStyle_06 {
  font-family: $fontMin;
  text-align: center;
  @include fz_vw(32);
  line-height: (42/32) + em;
  letter-spacing: 0.08em;
  > span {
    display: inline-block;
    padding-bottom: 12 / $design-num-sp * 100vw;
    margin-bottom: 34 / $design-num-sp * 100vw;
    border-bottom: 2 / $design-num-sp * 100vw solid $key-color;
  }
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_06 {
    font-size: 3.2rem;
    > span {
      padding-bottom: 12px;
      margin-bottom: 54px;
      border-bottom: 4px solid $key-color;
    }
  }
}
///////////////////////////////////
.ttlStyle_07 {
  @extend .ttlStyle_05;
  padding-top: 82 / $design-num-sp * 100vw;
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_07 {
    padding-top: 130px;
  }
}
///////////////////////////////////
.ttlStyle_08 {
  @include fz_vw(36);
  line-height: 1em;
  margin-bottom: 24 / $design-num-sp * 100vw;
  color: $key-color;
  font-weight: normal;
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_08 {
    font-size: 2.1rem;
    margin-bottom: 16px;
  }
}
///////////////////////////////////
.ttlStyle_09 {
  text-align: center;
  margin-bottom: 45 / $design-num-sp * 100vw;
  > .inner {
    display: inline-block;
    position: relative;
  }
  .col-icon {
    position: absolute;
    width: 120 / $design-num-sp * 100vw;
    left: -140 / $design-num-sp * 100vw;
    top: 0 / $design-num-sp * 100vw;
  }
  h3 {
    font-family: $fontMin;
    display: inline-block;
    line-height: (45/32) + em;
    @include fz_vw(32);
    border-bottom: 2 / $design-num-sp * 100vw solid $key-orange;
    padding-bottom: 10 / $design-num-sp * 100vw;
    letter-spacing: 0.03em;
    &::before,
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }
    &::before {
      border-width: 14 / $design-num-sp * 100vw 10 / $design-num-sp * 100vw 0 10 /
        $design-num-sp * 100vw;
      border-color: $key-orange transparent transparent transparent;
      bottom: -11 / $design-num-sp * 100vw;
      left: -webkit-calc(50% - 8px);
      left: calc(50% - 8px);
    }
    &::after {
      border-width: 10 / $design-num-sp * 100vw 6 / $design-num-sp * 100vw 0 6 /
        $design-num-sp * 100vw;
      border-color: $white transparent transparent transparent;
      bottom: -7 / $design-num-sp * 100vw;
      left: -webkit-calc(50% - 5.5px);
      left: calc(50% - 5.5px);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_09 {
    margin-bottom: 56px;
    .col-icon {
      width: 132px;
      left: -150px;
      top: -30px;
    }
    h3 {
      line-height: 1em;
      font-size: 3.2rem;
      border-bottom: 4px solid $key-orange;
      padding-bottom: 14px;
      &::before {
        border-width: 20px 13px 0 13px;
        bottom: -16px;
        left: -webkit-calc(50% - 13px);
        left: calc(50% - 13px);
      }
      &::after {
        border-width: 12px 7px 0 7px;
        bottom: -8px;
        left: -webkit-calc(50% - 7px);
        left: calc(50% - 7px);
      }
    }
  }
}
///////////////////////////////////
.ttlStyle_10 {
  line-height: 1em;
  @include fz_vw(30);
  font-family: $fontMin;
  margin-bottom: 20 / $design-num-sp * 100vw;
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_10 {
    font-size: 3.2rem;
    margin-bottom: 25px;
  }
}
///////////////////////////////////
.ttlStyle_11 {
  line-height: 1.7em;
  @include fz_vw(32);
  font-weight: normal;
  margin-bottom: 5 / $design-num-sp * 100vw;
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_11 {
    font-size: 3.2rem;
    border-bottom: 2px solid $black;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
///////////////////////////////////
.ttlStyle_12 {
  color: $key-color;
  @include fz_vw(32);
  line-height: 1em;
  margin-bottom: 10 / $design-num-sp * 100vw;
  font-weight: normal;
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_12 {
    font-size: 2.3rem;
    margin-bottom: 12px;
  }
}
///////////////////////////////////
.ttlStyle_13 {
  @include fz_vw(38);
  line-height: 1em;
  margin-bottom: 18 / $design-num-sp * 100vw;
  color: $key-color;
}
@media screen and (min-width: $break-point-middle) {
  .ttlStyle_13 {
    font-size: 4rem;
    margin-bottom: 15px;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.summaryStyle_01 {
  line-height: (56/26) + em;
  @include fz_vw(26);
  text-align: justify;
  letter-spacing: 0.03em;
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_01 {
    line-height: (56/18) + em;
    font-size: 1.8rem;
  }
}
///////////////////////////////
.summaryStyle_02 {
  line-height: (56/26) + em;
  @include fz_vw(26);
  text-align: justify;
  letter-spacing: -0.01em;
  &.style_01 {
    text-align: center;
  }
  > * {
    margin-bottom: 2em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_02 {
    line-height: (52/20) + em;
    font-size: 2rem;
    &.style_02 {
      text-align: center;
    }
  }
}
///////////////////////////////
.summaryStyle_03 {
  line-height: (56/26) + em;
  @include fz_vw(26);
  text-align: justify;
  > * {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_03 {
    line-height: (32/18) + em;
    font-size: 1.8rem;
  }
}
///////////////////////////////
.summaryStyle_04 {
  line-height: (56/26) + em;
  @include fz_vw(26);
  text-align: justify;
  > * {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_04 {
    line-height: (42/18) + em;
    font-size: 1.8rem;
  }
}
///////////////////////////////
.summaryStyle_05 {
  line-height: (45/26) + em;
  @include fz_vw(26);
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_05 {
    line-height: (32/20) + em;
    font-size: 2rem;
  }
}
///////////////////////////////
.summaryStyle_06 {
  line-height: (39/19) + em;
  @include fz_vw(19);
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_06 {
    line-height: (45/20) + em;
    font-size: 2rem;
  }
}
///////////////////////////////
.summaryStyle_07 {
  line-height: (56/26) + em;
  @include fz_vw(26);
  text-align: center;
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_07 {
    line-height: (52/24) + em;
    font-size: 2.4rem;
  }
}
///////////////////////////////
.summaryStyle_08 {
  line-height: (46/26) + em;
  @include fz_vw(26);
}
@media screen and (min-width: $break-point-middle) {
  .summaryStyle_08 {
    line-height: (52/24) + em;
    font-size: 2.4rem;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.txStyle_01 {
  @include fz_vw(20);
  line-height: 1.7em;
}
@media screen and (min-width: $break-point-middle) {
  .txStyle_01 {
    font-size: 1.8rem;
    line-height: (32/18) + em;
  }
}
////////////////////////////////////
.txStyle_02 {
  color: $key-orange;
  @include fz_vw(26);
  line-height: (38/26) + em;
}
@media screen and (min-width: $break-point-middle) {
  .txStyle_02 {
    font-size: 2rem;
    line-height: (42/20) + em;
  }
}
////////////////////////////////////
.txStyle_03 {
  color: $key-color;
}
////////////////////////////////////
.txStyle_04 {
  letter-spacing: -0.03em;
}
@media screen and (min-width: $break-point-middle) {
  .txStyle_04 {
    letter-spacing: 0em;
  }
}
////////////////////////////////////
@media screen and (max-width: 769px) {
  .txStyle_05 {
    @include fz_vw(22);
  }
}
////////////////////////////////////
.txStyle_06 {
  color: #808080;
  line-height: (24/20) + em;
  @include fz_vw(20);
}
@media screen and (min-width: $break-point-middle) {
  .txStyle_06 {
    line-height: (24/15) + em;
    font-size: 1.5rem;
  }
}
////////////////////////////////////
.txStyle_07 {
  color: $err-color;
  font-size: 1.6rem;
}
////////////////////////////////////
.txStyle_08 {
  color: $err-color;
  line-height: (40/20) + em;
  @include fz_vw(20);
  margin-top: 10 / $design-num-sp * 100vw;
}
@media screen and (min-width: $break-point-middle) {
  .txStyle_08 {
    line-height: (56/26) + em;
    font-size: 1.6rem;
    margin-top: 10px;
  }
}
////////////////////////////////////
@media screen and (max-width: 769px) {
  .txStyle_09 {
    line-height: (38/19) + em;
    @include fz_vw(19);
  }
}
////////////////////////////////////
.txStyle_10 {
  text-align: right;
  line-height: 1em;
  @incldue fz_vw(14);
  color: #666;
  > span {
    // font-weight: bold;
    // padding-right: 3px;
  }
}
@media screen and (max-width: 769px) {
  .txStyle_10 {
    font-size: 1.4rem;
  }
}
////////////////////////////////////
.txStyle_11 {
  @include fz_vw(30);
  line-height: 1.6em;
  text-align: center;
  margin-bottom: 30 / $design-num-sp * 100vw;
  font-family: $fontMin;
  font-weight: bold;
}
@media screen and (min-width: $break-point-middle) {
  .txStyle_11 {
    font-size: 3rem;
    line-height: 1em;
    margin-bottom: 60px;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.dlStyle_01 {
  dl {
    margin-bottom: 20 / $design-num-sp * 100vw;
    @include fz_vw(24);
    line-height: 1.7em;
    @include setTbl;
    > * {
      vertical-align: top;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  dt {
    width: 4em;
  }
  dd {
    padding-left: 1.5em;
  }
}
@media screen and (min-width: $break-point-middle) {
  .dlStyle_01 {
    dl {
      margin-bottom: 16px;
      font-size: 2rem;
      line-height: 1.7em;
      @include setTbl;
      &:last-child {
        margin-bottom: 0;
      }
    }
    dt {
      width: 3em;
    }
    dd {
      padding-left: 1.5em;
    }
  }
}
/////////////////////////////////////
.dlStyle_02 {
  @include setTbl;
  dt {
    width: 4em;
  }
  dd {
    position: relative;
    padding-left: 1em;
    &::before {
      content: "：";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .dlStyle_02 {
    @include setTbl;
    dt {
      width: 5em;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.gridStyle_01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 184 / $design-num-sp * 100vw;
  }
  img {
    @include imgset;
  }
}
@media screen and (min-width: $break-point-middle) {
  .gridStyle_01 {
    .item {
      width: 300 / $content-num-base * 100%;
    }
  }
}
/////////////////////////////
.gridStyle_02 {
  @include setTbl;
  > * {
    height: 270 / $design-num-sp * 100vw;
  }
  img {
    @include imgset;
    @media all and (-ms-high-contrast: none) {
      width: auto;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .gridStyle_02 {
    > * {
      height: 361 / $design-num * 100vw;
    }
  }
}
@media screen and (min-width: $design-width) {
  .gridStyle_02 {
    > * {
      height: 361px;
    }
  }
}
/////////////////////////////
.gridStyle_03 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 1/3 * 100%;
    overflow: hidden;
    //height: 200/$design-num-sp*100vw;
  }
  img {
    @include imgset;
    @media all and (-ms-high-contrast: none) {
      width: auto;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .gridStyle_03 {
    .item {
      height: 360 / $design-num * 100vw;
    }
  }
}
@media screen and (min-width: $design-width) {
  .gridStyle_03 {
    .item {
      height: 360px;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.btnStyle_01 {
  border: 1px solid $key-orange;
  color: $key-orange;
  padding: 26 / $design-num-sp * 100vw;
  @include fz_vw(34);
  line-height: 1em;
  position: relative;
  background-color: $white;
  overflow: hidden;
  > span {
    position: relative;
  }
  > span.icon {
    position: absolute;
    right: 36 / $design-num-sp * 100vw;
    top: 25%;
    line-height: 1em;
    @include fz_vw(50);
    transition: 0.3s;
  }
}
@media screen and (min-width: $break-point-middle) {
  .btnStyle_01 {
    min-width: 384px;
    padding: 18px;
    font-size: 3.4rem;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      position: absolute;
      left: -100%;
      top: 0;
      transition: 0.3s;
      background-color: $key-orange;
    }
    > span.icon {
      right: 30px;
      font-size: 3.6rem;
    }
    &:hover {
      color: $white;
      > span.icon {
        right: 15px;
      }
      &::before {
        left: 0;
      }
    }
  }
}
////////////////////////////////
.btnStyle_02 {
  @include btnsetting;
  border: 2px solid $key-color;
  color: $key-color;
  padding: 26 / $design-num-sp * 100vw;
  @include fz_vw(26);
  line-height: 1em;
  position: relative;
  background-color: $white;
  overflow: hidden;
  > span {
    position: relative;
  }
  > span.icon {
    position: absolute;
    right: 28 / $design-num-sp * 100vw;
    top: 21%;
    line-height: 1em;
    @include fz_vw(50);
    transition: 0.3s;
  }
  &.def {
    opacity: 0.3;
    background-color: #eee;
    cursor: default;
    pointer-events: none;
  }
}
@media screen and (min-width: $break-point-middle) {
  .btnStyle_02 {
    min-width: 384px;
    padding: 24px;
    font-size: 2rem;
    text-align: left;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      position: absolute;
      left: -100%;
      top: 0;
      transition: 0.3s;
      background-color: $key-color;
    }
    > span.icon {
      top: 24%;
      right: 30px;
      font-size: 3.6rem;
    }
    &:hover {
      color: $white;
      > span.icon {
        right: 15px;
      }
      &::before {
        left: 0;
      }
    }
  }
}
////////////////////////////////
.btnStyle_03 {
  @extend .btnStyle_02;
  text-align: center;
}
@media screen and (min-width: $break-point-middle) {
  .btnStyle_03 {
    text-align: left;
  }
}
////////////////////////////////
.btnStyle_04 {
  @extend .btnStyle_02;
  color: $black;
  border: 1px solid #959595;
  text-align: center;
}
@media screen and (min-width: $break-point-middle) {
  .btnStyle_04 {
    border: 2px solid #959595;
    &:hover {
      border: 2px solid $key-color;
    }
  }
}
////////////////////////////////
.btnStyle_05 {
  @extend .btnStyle_04;
  text-align: center;
  > span.icon {
    right: auto;
    left: 28 / $design-num-sp * 100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
  .btnStyle_05 {
    > span.icon {
      left: 30px;
    }
    &:hover {
      > span.icon {
        left: 15px;
        right: auto;
      }
    }
  }
}
////////////////////////////////
.btnStyle_06 {
  @extend .btnStyle_04;
  color: $white;
  border: 1px solid $key-color;
  background-color: $key-color;
}
@media screen and (min-width: $break-point-middle) {
  .btnStyle_06 {
    border: 2px solid $key-color;
    &:hover::before {
      background-color: lighten($key-color, 5%);
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tblStyle_01 {
  width: 100%;
  th,
  td {
    padding-top: 5 / $design-num-sp * 100vw;
    padding-bottom: 5 / $design-num-sp * 100vw;
    @include fz_vw(24);
    line-height: (54/20) + em;
  }
  th {
    padding-right: 1em;
    text-align: right;
    width: 7em;
    white-space: nowrap;
    font-weight: normal;
  }
  td {
    padding-left: 1em;
  }
  td {
    border-left: 1px solid $black;
  }
  tr:nth-child(1) {
    th,
    td {
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      top: -10px;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .tblStyle_01 {
    th,
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 2rem;
      line-height: (52/20) + em;
    }
    th {
      padding-right: 2em;
    }
    td {
      padding-left: 2em;
    }
  }
}
//////////////////////////////////
.tblStyle_02 {
  width: 100%;
  @include fz_vw(22);
  th,
  td {
    padding-top: 8 / $design-num-sp * 100vw;
    padding-bottom: 8 / $design-num-sp * 100vw;
  }
  th {
    padding-left: 8 / $design-num-sp * 100vw;
    padding-right: 16 / $design-num-sp * 100vw;
    font-weight: normal;
  }
  td {
    width: 130 / $design-num-sp * 100vw;
    white-space: nowrap;
  }
  thead {
    background-color: $key-green;
    color: $white;
    th {
      line-height: 1em;
      font-weight: bold;
      &:last-child {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #728080;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .tblStyle_02 {
    font-size: 1.8rem;
    th,
    td {
      padding-top: 14px;
      padding-bottom: 14px;
    }
    th {
      padding-left: 28px;
      padding-right: 28px;
    }
    td {
      width: 210/830 * 100%;
    }
  }
}
//////////////////////////////////
.tblStyle_03 {
  display: block;
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  tr {
    border-bottom: 1px solid $key-gray2;
    padding: 50 / $design-num-sp * 100vw 0;
    &:first-child {
      padding-top: 0;
    }
  }
  th {
    font-weight: normal;
    padding-bottom: 18 / $design-num-sp * 100vw;
    .ttl {
      line-height: 1.7em;
      @include fz_vw(28);
    }
    &.req span::after {
      content: "※必須";
      color: $err-color;
      @include fz_vw(24);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .tblStyle_03 {
    @include resetTbl;
    display: table;
    width: 100%;
    tr {
      padding: 0;
    }
    th,
    td {
      padding: 30px 0 50px;
      vertical-align: top;
    }
    th {
      width: 288/830 * 100%;
      .ttl {
        font-size: 1.8rem;
      }
      &.req span::after {
        font-size: 1.6rem;
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.linkStyle_01 {
  text-decoration: none;
  color: $text-color;
}
@media screen and (min-width: $break-point-middle) {
  .linkStyle_01 {
    &:hover {
      color: $hover-color;
      text-decoration: underline;
    }
  }
}
///////////////////////////////
.linkStyle_02 {
  color: $hover-color;
  text-decoration: none;
}
@media screen and (min-width: $break-point-middle) {
  .linkStyle_02 {
    &:hover {
      color: darken($hover-color, 10%);
      text-decoration: underline;
    }
  }
}
///////////////////////////////
.linkStyle_03 {
  display: inline-block;
  transition: 0.3s;
  img {
    display: block;
    backface-visibility: hidden;
  }
}
@media screen and (min-width: $break-point-middle) {
  .linkStyle_03 {
    opacity: 0.8;
  }
}
///////////////////////////////
.linkStyle_04 {
  color: $text-color;
  text-decoration: underline;
}
@media screen and (min-width: $break-point-middle) {
  .linkStyle_04 {
    &:hover {
      color: $hover-color;
      text-decoration: none;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.listStyle_01 {
  li {
    padding-left: 1em;
    text-indent: -1em;
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
////////////////////////////////////
.listStyle_02 {
  li {
    position: relative;
    padding-left: 1em;
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "・";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
////////////////////////////////////
.listStyle_03 {
  li {
    position: relative;
    padding-left: 1.2em;
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "●";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.imgStyle_01 {
  img {
    @include imgset;
    @media all and (-ms-high-contrast: none) {
      width: auto;
    }
  }
}
//////////////////////////////////////
.imgStyle_02 {
  overflow: hidden;
  img {
    @include imgset;
    max-width: 998px;
    margin: 0 auto;
  }
  > span {
    display: block;
    position: relative;
    &::after {
      display: none;
      content: "";
      width: 100%;
      height: 500px;
      position: absolute;
      background-color: $white;
      top: 0;
      left: 0;
      transition: 0.6s ease-in;
      transition-delay: 1s;
    }
  }
  &.active > span::after {
    top: -800px;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.navStyle_01 {
  margin-bottom: 100 / $design-num-sp * 100vw;
  ul {
    @include setTbl;
    border: 1px solid $key-color;
    border-radius: 5px;
  }
  a {
    display: block;
    text-align: center;
    padding: 20 / $design-num-sp * 100vw;
    @include fz_vw(30);
    line-height: 1.6em;
  }
  .active a {
    background-color: $key-color;
    color: $white;
    font-weight: bold;
    cursor: default;
    pointer-events: none;
  }
}
@media screen and (min-width: $break-point-middle) {
  .navStyle_01 {
    margin-bottom: 100px;
    ul {
      @include setTbl;
      border: 2px solid $key-color;
      border-radius: 5px;
    }
    a {
      padding: 20px;
      font-size: 1.8rem;
      &:hover {
        background-color: lighten($key-color, 20%);
        color: $white;
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
