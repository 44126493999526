@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_01{
  margin: 30/$design-num-sp*100vw 0 0;
  padding: 0 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_01{
  margin: 60px 0 0;
  padding: 0;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_02{
  margin-top: 48/$design-num-sp*100vw;
  //margin-bottom: 120/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_02{
  margin-top: -30px;
  margin-bottom: 150px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_03{
  margin-top: -100/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_03{
  margin-top: -120px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_04{
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_04{
  margin-bottom: 50px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_05{
  margin-top: -330/$design-num-sp*100vw;
  margin-bottom: 95/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_05{
  margin-top: 0;
  margin-bottom: 200px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_06{
  margin-bottom: 110/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_06{
  margin-bottom: 80px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_07{
  margin-bottom: 65px;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_08{
  padding-bottom: 110/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_08{
  padding-bottom: 80px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_09{
  @include baseSpPad;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_09{
  @include contentWrap;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_10{
  margin-bottom: 110/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_10{
  margin-bottom: 150px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_11{
  margin-bottom: 50/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_11{
  margin-bottom: 40px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_12{
  margin-bottom: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_12{
  margin-bottom: 40px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_13{
  margin-bottom: 65/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_13{
  margin-bottom: 80px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_14{
  margin-bottom: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_14{
  margin-bottom: 20px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_15{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_15{
  margin-bottom: 120px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_16{
  margin-bottom: 26/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_16{
  margin-bottom: 30px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_17{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_17{
  margin-bottom: 60px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_18{
  margin-bottom: 46/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_18{
  margin-bottom: 50px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_19{
  margin-bottom: 110/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_19{
  margin-bottom: 170px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_20{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_20{
  margin-bottom: 40px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_21{
  margin-top: 10/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_21{
  margin-top: 30px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_22{
  margin-bottom: 10/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_22{
  margin-bottom: 20px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_23{
  position: relative;
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_23{
  margin-top: -50px;
  margin-bottom: 80px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_24{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_24{
  margin-bottom: 60px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_25{
  padding-top: 100/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_25{
  padding-top: 70px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_26{
  margin-bottom: 16/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_26{
  margin-bottom: 10px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setZ_01{
  z-index: 2000;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
