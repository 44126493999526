@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header{
  position: fixed;
  width: 100%;
  top: -100px;
  left: 0;
  background-color: $white;
  z-index: 3000;
  padding: 30/$design-num-sp*100vw 40/$design-num-sp*100vw 20/$design-num-sp*100vw;
  transition: .3s;
  .pages &{
    top: 0;
  }
  .menuOpen &{
    top: 0;
    background-color: transparent;
    .col-logo{
      opacity:0;
    }
  }
  .fixedHeader &{
    top: 0;
    @include underShadow;
  }
  .col-nav{
    display: none;
  }
  .logo a{
    svg{
      fill:$black;
      width: 318/$design-num-sp*100vw;
      height: 50/$design-num-sp*100vw;
      .st0{
        fill:$key-color;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
header{
  padding: 24px 0;
  >.inner{
    @include setTbl;
    @include contentWrap;
  }
  .col-nav{
    display: table-cell;
  }
  .col-logo{
    width: 295/$content-num-base*100%;
  }
  .logo a{
    svg{
      width: 221px;
      height: 35px;
    }
    &:hover{
      opacity:.8;
    }
  }
}
}
@media screen and (min-width: 870px) {
header{
  .logo a{
    svg{
      fill:$black;
      width: 295px;
      height: 46px;
      .st0{
        fill:$key-color;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header .gNav{
  ul{
    @include setTbl;
    table-layout: auto;
    width: auto;
    margin: 0 0 0 auto;
  }
  li{
    border-right: 1px solid $black;
    padding: 0 10px;
    &:last-child{
      border: none;
    }
  }
  a{
    display: block;
    font-size: 1.8rem;
    line-height: 1em;
    color: $black;
    overflow: hidden;
    @include over_01($hover-color);
    &:hover{
      color: $hover-color;
    }
    &.current,&.parentsLink{
      cursor: default;
      pointer-events: none;
      color: $key-color;
    }
    @media all and (-ms-high-contrast: none) {
      padding-top: .5em;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#burgerMenu{
  width: 34/$design-num-sp*100vw;
  height: 30/$design-num-sp*100vw;
  margin: 0 auto;
  position: absolute;
  right: 40/$design-num-sp*100vw;
  top: 40/$design-num-sp*100vw;
  >.inner{
    @include transition(.3s);
    height: 100%;
    cursor: pointer;
  }
  .burger{
    height: 100%;
    >*{
      height: 100%;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
#burgerMenu{
  display: none;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.menu-trigger {
  position: relative;
  width: 100%;
  height: 100%;
  display:block;
}
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 100%;
  height: 4/$design-num-sp*100vw;
  background-color:$black;
  .menuOpen &{
    background-color: $white;
  }
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  top: -webkit-calc(50% - 1px);
  top: calc(50% - 1px);
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.active .menu-trigger span:nth-of-type(1) {
  @include transform(translateY(13/$design-num-sp*100vw) rotate(-136deg));
}
.active .menu-trigger span:nth-of-type(2) {
	opacity: 0;
}
.active .menu-trigger span:nth-of-type(3) {
  width: 100%;
  @include transform(translateY(-13/$design-num-sp*100vw) rotate(136deg));
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
