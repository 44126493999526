@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.articleStyle_01{
  position: relative;
  .bgImg{
    display: block;
    height: 448/$design-num-sp*100vw;
    >span{
      display: block;
      @include bgRatio;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  .summary{
    background-color: get_keycolor(.7);
    color: $white;
    padding: 40/$design-num-sp*100vw;
  }
  .summary-inner{
    @include fz_vw(26);
    line-height: (56/26)+em;
    text-align: justify;
  }
}
$baseH1:670/1280*100vw;
@media screen and (min-width: $break-point-middle) {
.articleStyle_01{
  height: $baseH1;
  .bgImg{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content{
    position: relative;
    @include setTbl;
    height: 100%;
    >.inner{
      text-align: right;
    }
  }
  .summary{
    display: inline-block;
    width: 50vw;
    padding: 66/$design-num*100vw 70/$design-num*100vw;
  }
  .summary-inner{
    text-align: left;
    width: 442/$design-num*100vw;
    font-size: 1.8rem;
    line-height: (56/18)+em;
  }
}
}
@media screen and (min-width: $content-width-base) {
.articleStyle_01{
  .summary{
    padding: 66px 0px 66px 70px;
  }
}
}
////////////////////////////////
.articleStyle_02{
  @extend .articleStyle_01;
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_02{
  height: auto;
  .bgImg{
    height: $baseH1;
  }
  .content{
    display: block;
    padding-top: 476/$design-num*100vw;
  }
  .summary-inner{
    margin: 0 0 0 auto;
  }
}
}
@media screen and (min-width: $content-width-base) {
.articleStyle_02{
  .summary{
    padding: 66px 70px 66px 0px;
  }
}
}
////////////////////////////////
.articleStyle_03{
  padding: 40/$design-num-sp*100vw 0 150/$design-num-sp*100vw;
  position: relative;
  &::before{
    content: '';
    width: 1px;
    height: 100/$design-num-sp*100vw;
    position: absolute;
    left:50%;
    bottom: 0;
    background-color: $black;
  }
  >.inner{
    position: relative;
  }
  .ttlSec{
    @include setTbl;
  }
  .col-icon{
    color: $key-color;
    width: 140/$design-num-sp*100vw;
    .icon{
      @include fz_vw(140);
      line-height: 1em;
    }
  }
  .col-ttl{
    padding-left: 35/$design-num-sp*100vw;
    h3{
      color: $key-color;
      font-family: $fontMin;
      line-height: 1.7em;
      @include fz_vw(38);
      font-weight: 600;
      margin-bottom:16/$design-num-sp*100vw;
      .sm{
        @include fz_vw(27);
      }
      .num{
        display: inline-block;
        background-color: $key-color;
        color: $white;
        border-radius: 10/$design-num-sp*100vw;
        line-height: 1em;
        width: (32/28)+em;
        position: relative;
        top: -1px;
        padding: 5/$design-num-sp*100vw 0;
        @include fz_vw(36);
        text-align: center;
        margin-right: 10/$design-num-sp*100vw;
      }
      &.style_01{
        @include fz_vw(32);
      }
    }
    .summary{
      line-height: (50/26)+em;
      @include fz_vw(26);
    }
  }
  &.style_01,&.style_02{
    padding-top: 0;
    padding-bottom: 100/$design-num-sp*100vw;
    .col-icon{
      color: #808080;
    }
    .col-ttl{
      h3{
        color: #808080;
        margin-bottom: 0;
        .num{
          background-color: #808080;
        }
      }
    }
  }
  &.style_02{
    padding-bottom: 0;
    &::before{
      display: none;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_03{
  $sepPos:340;
  padding: 58px 0 165px;
  &::before{
    width: 1px;
    height: 100px;
    left:$sepPos+px;
  }
  >.inner{
    padding-left: $sepPos/$content-num-base*100%;
  }
  .ttlSec{
    display: block;
    >*{
      display: block;
    }
  }
  .col-icon{
    position: absolute;
    left: 0;
    top: 0;
    width: $sepPos/$content-num-base*100%;
    text-align: center;
    .icon{
      font-size: 16rem;
    }
  }
  .col-ttl{
    padding-left: 0;
    h3{
      line-height: 1em;
      font-size: 3.2rem;
      margin-bottom: 30px;
      .sm{
        font-size: 3.2rem;
      }
      .num{
        border-radius: 5px;
        padding: 3px 0;
        font-size: 2.8rem;
        margin-right: 6px;
      }
      &.style_01{
        font-size: 3.2rem;
      }
    }
    .summary{
      line-height: (42/20)+em;
      font-size: 2.0rem;
    }
  }
  &.style_01,&.style_02{
    padding-top: 58px;
    padding-bottom: 165px;
    .col-icon{
      top: -7rem;
    }
  }
  &.style_02{
    padding-bottom: 0;
  }
}
}
//////////////////////////
.articleStyle_04{
  margin-top: 40/$design-num-sp*100vw;
  border: 1px solid $key-orange;
  h2{
    color: $white;
    background-color: $key-orange;
    @include fz_vw(26);
    line-height: 1.7em;
    padding: 10/$design-num-sp*100vw 18/$design-num-sp*100vw;
    cursor: pointer;
    transition: opacity .3s;
    font-weight: normal;
  }
  .accIcon{
    @include fz_vw(18);
    &::before,&::after{
      @include iconFont;
      padding-left: 10/$design-num-sp*100vw;
    }
    &::before{
      content: '\EA08';
    }
    &::after{
      content: '\EA09';
      display: none;
    }
  }
  .active .accIcon{
    &::before{
      display: none;
    }
    &::after{
      display: inline;
    }
  }
  .summary{
    background-color: $white;
    line-height: (50/26)+em;
    @include fz_vw(26);
    padding: 30/$design-num-sp*100vw 26/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_04{
  margin-top: 30px;
  h2{
    font-size: 1.8rem;
    padding: 6px 24px;
    &:hover{
      opacity: .9;
    }
  }
  .accIcon{
    font-size: 1.3rem;
    &::before,&::after{
      padding-left: 10px;
    }
  }
  .summary{
    line-height: (32/18)+em;
    font-size: 1.8rem;
    padding: 12px 24px;
  }
}
}
//////////////////////////
.articleStyle_05{
  padding-bottom: 150/$design-num-sp*100vw;
  position: relative;
  margin-bottom: 40/$design-num-sp*100vw;
  &::before{
    content: '';
    width: 1px;
    height: 100/$design-num-sp*100vw;
    position: absolute;
    left:50%;
    bottom: 0;
    background-color: $black;
  }
  &.style_01{
    padding-bottom: 0;
    margin-bottom: 0;
    &::before{
      display: none;
    }
  }
  >.inner{
    margin-bottom: 40/$design-num-sp*100vw;
  }
  .col-img{
    width: 220/$design-num-sp*100%;
    margin: 0 auto 30/$design-num-sp*100vw;
  }
  .summary{
    line-height: (56/26)+em;
    @include fz_vw(26);
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_05{
  $sepPos:340;
  padding-bottom: 165px;
  margin-bottom: 60px;
  &::before{
    height: 100px;
    left:$sepPos+px;
  }
  &.style_01{
    padding-bottom: 0;
    margin-bottom: 0;
  }
  >.inner{
    margin-bottom: 65px;
    @include setTbl;
    >*{
      vertical-align: bottom;
    }
  }
  .col-img{
    width: 260/$content-num-base*100%;
    margin: 0;
  }
  .col-content{
    padding-left: 80/$content-num-base*100%;
  }
  .summary{
    line-height: (42/20)+em;
    font-size: 2.0rem;
  }
}
}
//////////////////////////
.articleStyle_06{
  >.inner{
    @include setTbl;
  }
  .col-content{
    position: relative;
  }
  .col-img{
    width: 198/$design-num-sp*100vw;
    >*{
      position: relative;
      overflow: hidden;
      border-radius: 100%;
      z-index: 1;
    }
  }
  .ttlSec{
    border-bottom: 1px solid $black;
    padding-bottom: 10/$design-num-sp*100vw;
    margin-bottom: 20/$design-num-sp*100vw;
    @include setTbl;
    >*{
      vertical-align: top;
    }
    .time{
      line-height: 1.6em;
      @include fz_vw(26);
      width: 88/$design-num-sp*100vw;
    }
    h3{
      line-height: 1.6em;
      padding-right: 30/$design-num-sp*100vw;
      @include fz_vw(28);
      color: $key-color;
      font-weight: normal;
    }
  }
  .summary{
    @include fz_vw(26);
    line-height: 1.8em;
    p.style_01{
      @include fz_vw(22);
      line-height: 1.8em;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_06{
  .col-img{
    width: 250/720*100%;
  }
  .ttlSec{
    padding-bottom: 0px;
    margin-bottom: 10px;
    .time{
      font-size: 3.2rem;
      width: 130/470*100%;
      padding-left: 20/470*100%;
    }
    h3{
      padding-right: 30/470*100%;
      font-size: 3.0rem;
    }
  }
  .summary{
    padding-left: 135/470*100%;
    font-size: 2.2rem;
    p.style_01{
      font-size: 1.8rem;
    }
  }
}
}
//////////////////////////
.articleStyle_07{
  background-color: $white;
  border: 1px solid #959595;
  >.inner{
    padding: 46/$design-num-sp*100vw 30/$design-num-sp*100vw 30/$design-num-sp*100vw;
  }
  h3{
    @include fz_vw(30);
    line-height: 1em;
    color: $key-color;
    letter-spacing: .05em;
    text-align: center;
    font-family: $fontMin;
    margin-bottom: 16/$design-num-sp*100vw;
  }
  .summary{
    line-height: (50/26)+em;
    @include fz_vw(26);
    text-align: justify;
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_07{
  border: 2px solid #959595;
  >.inner{
    padding: 46px 40/490*100% 40px;
  }
  h3{
    font-size: 3.2rem;
    margin-bottom: 42px;
  }
  .summary{
    line-height: (42/20)+em;
    font-size: 2.0rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
