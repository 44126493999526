@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.secStyle_01{
  position: relative;
  padding-top: 110/$design-num-sp*100vw;
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0 160/$design-num-sp*100vw 100vw;
    border-color: transparent transparent transparent $key-gray1;
    transition: left .6s;
  }
  &.active::before{
    left: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_01{
  padding-top: 130px;
  &::before{
    border-width: 0px 0 315/$design-num*100vw 100vw;
  }
}
}
@media screen and (min-width: $content-width-base) {
.secStyle_01{
  &::before{
    border-width: 0px 0 315px 100vw;
  }
}
}
//////////////////////////
.secStyle_02{
  position: relative;
  padding-top: 100/$design-num-sp*100vw;
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 200/$design-num-sp*100vw 0vw 200/$design-num-sp*100vw 100vw;
    border-color: transparent transparent transparent $key-gray1;
    transition:.6s;
  }
  &.active::before{
    left: 0;
  }
  >.inner{
    position: relative;
    @include baseSpPad;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_02{
  padding-top: 230px;
  &::before{
    border-width: 345/$design-num*100vw 100vw 345/$design-num*100vw 0;
    border-color: transparent $key-gray1 transparent transparent;
    left: auto;
    right: -100%;
    transition: right .6s;
  }
  &.active::before{
    left: auto;
    right: 0;
  }
  >.inner{
    @include contentWrap;
  }
}
}
@media screen and (min-width: $content-width-base) {
.secStyle_02{
  &::before{
    border-width: 345px 100vw 345px 0;
  }
}
}
//////////////////////////
.secStyle_03{
  position: relative;
  padding-top: 490/$design-num-sp*100vw;
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 215/$design-num-sp*100vw 100vw 215/$design-num-sp*100vw 0vw;
    border-color: transparent $key-gray1 transparent transparent;
    z-index: -1;
    transition:.6s;
  }
  &.active::before{
    right: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_03{
  padding-top: 50px;
  &::before{
    border-width: 345/$design-num*100vw 0 345/$design-num*100vw 100vw;
    border-color: transparent transparent transparent $key-gray1;
    right: auto;
    left: -100%;
  }
  &.active::before{
    right: auto;
    left: 0;
  }
}
}
@media screen and (min-width: $content-width-base) {
.secStyle_03{
  &::before{
    border-width: 345px 0 345px 100vw;
  }
}
}
//////////////////////////
.secStyle_04{
  position: relative;
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 100/$design-num-sp*100vw;
    right: -100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 250/$design-num-sp*100vw 100vw 250/$design-num-sp*100vw 0vw;
    border-color: transparent $key-gray1 transparent transparent;
    transition:.6s;
  }
  &.active::before{
    right: 0;
  }
  >.inner{
    position: relative;
    @include baseSpPad;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_04{
  &::before{
    top: 0;
    border-width: 0 0 500/$design-num*100vw 100vw;
    border-color: transparent transparent $key-gray1 transparent;
  }
  >.inner{
    @include contentWrap;
  }
}
}
@media screen and (min-width: $content-width-base) {
.secStyle_04{
  &::before{
    border-width: 0 0 500px 100vw;
  }
}
}
//////////////////////////
.secStyle_05{
  >.inner{
    position: relative;
    @include baseSpPad;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_05{
  >.inner{
    @include contentWrap;
  }
}
}
//////////////////////////
.secStyle_06{
  border: 2/$design-num-sp*100vw solid $key-color;
  padding: 65/$design-num-sp*100vw 35/$design-num-sp*100vw;
  background-color: $white;
  position: relative;
  &.style_01::before{
    //content: url('../images/recruit_img_22_sp.jpg');
    content: '';
    width: 310/$design-num-sp*100vw;
    height: 310/$design-num-sp*100vw;
    background-image: url('../images/recruit_img_22_sp@3x.jpg');
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
  }
  >.inner{
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_06{
  border: 2px solid $key-color;
  padding: 65/$design-num*100vw 95/$design-num*100vw;
  &.style_01::before{
    width: auto;
    height: auto;
    background-image: none;
    content: url('../images/recruit_img_22.jpg');
  }
}
}
@media screen and (min-width: $content-width-base) {
.secStyle_06{
  padding: 65px 95px;
}
}
//////////////////////////
.secStyle_07{
  position: relative;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.colStyle_01{
  .col-img{
    >*{
      position: relative;
      width: 201/$design-num-sp*100vw;
      margin: -36/$design-num-sp*100vw 0 0 auto;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.colStyle_01{
  >.inner{
    @include setTbl;
    >*{
      vertical-align: top;
    }
  }
  .col-content{
    padding-right: 50/$content-num-base*100%;
  }
  .col-img{
    width: 300/$content-num-base*100%;
    >*{
      width: auto;
      margin: 0;
    }
    .imgStyle img{
      @include imgset;
    }
  }
}
}
////////////////////////////////////
.colStyle_02{
  .col-content{
    margin-bottom: 20/$design-num-sp*100vw;
  }
  .col-img{
    >*{
      width: 250/$design-num-sp*100vw;
      margin: 0 auto;
    }
    .imgStyle img{
      @include imgset;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.colStyle_02{
  >.inner{
    @include setTbl;
    >*{
      vertical-align: top;
    }
  }
  .col-content{
    margin-bottom: 0;
    padding-right: 50/$content-num-base*100%;
  }
  .col-img{
    width: 300/$content-num-base*100%;
    >*{
      width: auto;
      margin: 0;
    }
  }
}
}
////////////////////////////////////
.colStyle_03{
  >*{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.colStyle_03{
  @include setTbl;
  >*{
    vertical-align: top;
  }
  .col{
    margin-right: 20px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.elemWrap_01{
  text-align: center;
  >*{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemWrap_01{
  @include killSpace;
  text-align: center;
}
}
///////////////////////////////////
.elemWrap_02{
  display: block;
}
@media screen and (min-width: $break-point-middle) {
.elemWrap_02{
  >.inner{
    max-width: 845px;
    margin: 0 auto;
  }
}
}
///////////////////////////////////
.elemWrap_03{
  display: block;
  position: relative;
}
///////////////////////////////////
.elemWrap_04{
  display: block;
  >*{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemWrap_04{
  >*{
    max-width: 384px;
  }
}
}
///////////////////////////////////
.elemWrap_05{
  text-align: center;
  padding: 30/$design-num-sp*100vw 0 0;
  >*{
    display: block;
    width: 100%;
    margin-bottom: 50/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.elemWrap_05{
  text-align: center;
  padding: 50px 0 0;
  >*{
    display: inline-block;
    width: 384/830*100%;
    margin-bottom: 0;
  }
}
}
///////////////////////////////////
.elemWrap_06{
  @extend .elemWrap_05;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.articleWrap_01{
  >*{
    margin-bottom: 20/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.articleWrap_01{
  max-width: 720px;
  margin: 0 auto;
  >*{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
////////////////////////////////////
.articleWrap_02{
  >*{
    margin-bottom: 36/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.articleWrap_02{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  >*{
    margin-bottom: 0;
    width: 490/$content-num-base*100%;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
