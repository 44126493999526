@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer {
  padding-bottom: 120 / $design-num-sp * 100vw;
  background-color: $white;
  position: relative;
  > .inner {
    position: relative;
    @include baseSpPad;
  }
}
@media screen and (min-width: $break-point-middle) {
  footer {
    padding-bottom: 120px;
    > .inner {
      @include contentWrap;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#footNav {
  margin-bottom: 40 / $design-num-sp * 100vw;
  width: 285 / $design-num-sp * 100vw;
  li {
    display: block;
    @include fz_vw(24);
    line-height: 1em;
    border-bottom: 1px solid #c7c7c7;
    padding: 20 / $design-num-sp * 100vw 0;
    margin-bottom: 20 / $design-num-sp * 100vw 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    text-decoration: none;
    color: $black;
    &.current,
    &.parentsLink {
      cursor: default;
      pointer-events: none;
      color: $key-color;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  #footNav {
    margin-bottom: 40px;
    @include setTbl;
    table-layout: auto;
    white-space: nowrap;
    width: auto;
    ul {
      &:last-child {
        padding-left: 60px;
      }
    }
    li {
      font-size: 1.6rem;
      border: none;
      padding: 0;
      margin-bottom: 0;
      &:nth-child(n + 2) {
        padding-top: 20px;
      }
    }
    a {
      &:hover {
        color: $hover-color;
        text-decoration: underline;
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#copyrightSec {
  @include fz_vw(16);
  line-height: 1em;
  position: absolute;
  bottom: -4em;
  left: 0;
  @include baseSpPad;
}
@media screen and (min-width: $break-point-middle) {
  #copyrightSec {
    position: relative;
    bottom: auto;
    left: auto;
    padding: 0;
    font-size: 1.5rem;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#footLabel {
  // position: absolute;
  // right: 40 / $design-num-sp * 100vw;
  // bottom: 70 / $design-num-sp * 100vw;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  li {
    &:first-child {
      width: 370 / $design-num-sp * 100vw;
    }
    &:last-child {
      width: 152 / $design-num-sp * 100vw;
    }
  }
  a {
    display: block;
  }
  img {
    @include imgset;
    transition: 0.3s;
  }
}
@media screen and (min-width: $break-point-middle) {
  #footLabel {
    position: absolute;
    right: 0;
    bottom: 0;
    a:hover {
      opacity: 0.8;
    }
    ul {
      width: 573/1280 * 100vw;
      padding-right: 20/1280 * 100vw;
      justify-content: flex-end;
      gap: 20/1280 * 100vw;
    }
    li {
      &:first-child {
        width: 380/1280 * 100vw;
      }
      &:last-child {
        width: 152/1280 * 100vw;
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  #footLabel {
    position: absolute;
    right: 0;
    bottom: 0;
    a:hover {
      opacity: 0.8;
    }
    ul {
      width: 573px;
      justify-content: space-between;
      padding-right: 0;
      gap: 0;
    }
    li {
      &:first-child {
        width: 380px;
      }
      &:last-child {
        width: 152px;
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#footSns {
  margin-bottom: 60 / $design-num-sp * 100vw;
  ul {
    @include killSpace;
  }
  li {
    margin-right: 35 / $design-num-sp * 100vw;
    &:last-child {
      margin-right: 0;
    }
    img {
      @include imgset;
    }
  }
  .instagram {
    width: 35 / $design-num-sp * 100vw;
  }
  .youtube {
    width: 50 / $design-num-sp * 100vw;
  }
  a {
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
  #footSns {
    margin-bottom: 68px;
    li {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    .instagram {
      width: 42px;
    }
    .youtube {
      width: 60px;
    }
    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
