@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.carouselStyle{
  .screen{
      position: relative;
  }
  .screen>.carouselItem:nth-child(n+2){
      display: none;
  }
  button{
    outline:0;
    @include appearance(none);
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.carouselStyle_01{
  .owl-carousel .owl-item img{
    width: auto;
  }
  .owl-carousel .owl-stage-outer{
    overflow: visible;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
