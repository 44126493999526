@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#outerMenu{
	@include opacity(1);
	position: relative;
	.menuOpen &{
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#clickBlocker{
	width: 100%;
	height: 100%;
	position:fixed;
	top: 0;
	left: 0;
	z-index: 600;
	background-color:rgba(0, 0, 0, .8);
	display: none;
	.menuOpen &{
		display: block;
	}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuPanel{
	padding-top: 120/$design-num-sp*100vw;
	top: -50/$design-num-sp*100vw;
	opacity: 0;
	position: fixed;
	@include transition(.3s);
	width: 100%;
	z-index: 0;
	.menuOpen &{
		z-index: 2000;
		position:relative;
		top: 0;
		opacity: 1;
	}
	>.inner{
		padding: 0 40/$design-num-sp*100vw;
	}
}
@media screen and (min-width: $break-point-middle) {
#menuPanel{
	display: none;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuPanel .gNav{
	li{
		line-height: 1em;
		@include fz_vw(32);
		text-align: center;
		border-bottom: 1px solid #c7c7c7;
		&:last-child{
			border-bottom: none;
		}
		a{
			display: block;
			color: $white;
			padding: 36/$design-num-sp*100vw 0;
			&.current{
				cursor: default;
				pointer-events:none;
				text-decoration: underline;
			}
		}
	}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
