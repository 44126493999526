@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.formStyle{
  position:relative;
  //display: none;
  .wpcf7-not-valid-tip{
    display: block;
    color: $err-color;
    @include fz_vw(20);
    padding-top: 20/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.formStyle{
  .wpcf7-not-valid-tip{
    font-size: 1.6rem;
    padding-top: 20px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.confirmSec{
  //display: none;
  margin-bottom: 80/750*100vw;
}
@media screen and (min-width: $break-point-middle) {
.confirmSec{
  margin-bottom: 60px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.inputCol_01{
  @include setTbl;
  margin-bottom: 30/750*100vw;
  &:last-child{
    margin-bottom: 0;
  }
  >*{
    vertical-align: top;
    &:first-child{
      padding-right: 15/750*100vw;
    }
    &:last-child{
      padding-left: 15/750*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.inputCol_01{
  margin-bottom: 16px;
  &:last-child{
    margin-bottom: 0;
  }
  >*{
    &:first-child{
      padding-right: 10px;
    }
    &:last-child{
      padding-left: 10px;
    }
  }
}
}
////////////////////////////////
.inputCol_02{
  .wpcf7-list-item{
    display: block;
    margin-bottom: 16/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.inputCol_02{
  .wpcf7-list-item{
    margin-bottom: 8px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
////////////////////////////////
.inputCol_03{
  >*{
    display: inline-block;
  }
  .sep{
    width: 1em;
    text-align: center;
  }
  .col{
    width: 6.5em;
  }
  .col-tx{
    display: block;
    padding-top: 20/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.inputCol_03{
  @include setTbl;
  >*{
    vertical-align: middle;
    display: table-cell;
  }
  .sep{
    width: 2em;
  }
  .col{
    width: 10em;
  }
  .col-tx{
    display: table-cell;
    padding-top: 0;
    padding-left: 20px;
  }
}
}
////////////////////////////////
.inputCol_04{
  @extend .inputCol_03;
}
@media screen and (min-width: $break-point-middle) {
.inputCol_04{
  display: block;
  >*{
    display: inline-block;
  }
  .sep{
    width: 2em;
    text-align: center;
  }
  .col{
    width: 10em;
  }
}
}
////////////////////////////////
.inputCol_05{
  width: 280/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.inputCol_05{
  width: 178px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.inputStyle_01{
  input{
    @include inputStyle_01;
  }
  input:-ms-input-placeholder {
    color: $key-gray2!important;
  }
  &.setDatePicker{
    position: relative;
    &::before{
      @include iconFont;
      //content: '\EA12';
      position: absolute;
      color: #666;
      @include fz_vw(30);
      line-height: 0;
      position: absolute;
      right: 20/750*100vw;
      top:1.5em;
    }
  }
  &.setDatePicker input{
    cursor: pointer;
    background-color: transparent;
  }
  textarea{
    position: relative;
    @include inputStyle_01;
    border: 1px solid $key-gray2;
    padding: 10px;
    resize: none;
    &:focus{
     border: 1px solid $key-color;
    }
  }
  textarea:-ms-input-placeholder {
    color: $key-gray2!important;
  }
}
@media screen and (min-width: $break-point-middle) {
.inputStyle_01{
  &.setDatePicker{
    &::before{
      font-size: 1.2rem;
      top:2em;
      right: 10px;
    }
  }
}
}
//////////////////////////////////////////
.inputStyle_02{
	background-color:$white;
	position:relative;
  overflow: hidden;
	//border:1px solid #ccc;
	&::before{
		font-family: "myfont";
		content:"\EA08";
		position:absolute;
		line-height:1em;
		top:3.2em;
		right:9px;
		color:$key-gray2;
		z-index:1;
		font-size:8px;
	}
  &::after{
    content: '';
    height: 100px;
    width: 1px;
    background-color: $key-gray2;
    right: 26px;
    top: 0;
    display: block;
    position: absolute;
  }
	select{
    border-radius: 0;
		color:#525252;
		width:100%;
		position:relative;
		z-index:2;
		@include appearance(none);
    @include fz_vw(32);
		background-color:transparent;
		padding:0 8px;
		height:3em;
		outline:0;
    border: 1px solid $key-gray2;
		@include box-shadow(0);
		&:focus{
      border: 1px solid #999;
			@include box-shadow(0);
			outline:0;
		}
	}
	select::-ms-expand {
		display: none;
	}
}
@media screen and (min-width: $break-point-middle) {
.inputStyle_02{
	&:before{
    right:8px;
	}
	select{
    font-size: 1.8rem;
    height: 3.2em;
	}
	option{
		width:100%;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.radioStyle_01{
  input{
    display: none;
  }
  input + span{
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 40/$design-num-sp*100vw;
    @include fz_vw(32);
    line-height: 1em;
    font-weight: normal;
    line-height: 1.6em;
    &::before,&::after{
      @include iconFont;
      position: absolute;
      line-height: 1em;
      @include fz_vw(30);
      left: 0;
      top: 12/$design-num-sp*100vw;
    }
    &::before{
      content: "\EA0E";
      color: #ccc;
    }
    &::after{
      content: "\EA0F";
      color: $key-color;
      @include opacity(0);
      @include transition(.2s);
    }
  }
  input:checked + span{
    //cursor: default;
    //pointer-events:none;
    &::before{
      color: $key-color;
      //opacity:0;
    }
    &::after{
      @include opacity(1);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.radioStyle_01{
  input + span{
    padding-left: 30px;
    font-size: 2.0rem;
    position: relative;
    padding-left: 30px;
    &::before,&::after{
      font-size: 2.2rem;
      top: 5px;
    }
  }
}
}
/////////////////////////////////////////////
.radioStyle_02{
  @extend .radioStyle_01;
  input + span{
    @include fz_vw(26);
    &::before,&::after{
      top: 8/$design-num-sp*100vw;
    }
    &::before{
      content: "\EA10";
    }
    &::after{
      content: "\EA11";
    }
  }
  a{
    text-decoration: underline;
  }
  &.style_01{
    input + span::before{
      content: "\EA12";
      color: $white;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.radioStyle_02{
  input + span{
    font-size: 2.0rem;
    &::before,&::after{
      top: 5px;
    }
  }
  a{
    &:hover{
      text-decoration: none;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.optSec{
  padding: 60/750*100vw 0 60/750*100vw;
}
@media screen and (min-width: $break-point-middle) {
.optSec{
  text-align: center;
  padding: 50px 0 50px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.copyCheck{
  margin-bottom: 40/750*100vw;
}
.copyCheckConf{
  margin-bottom: 40/750*100vw;
  display: none;
  p{
    @include fz_vw(30);
  }
  &.show{
    display: block;
  }
}
.copyCheckCont{
  display: none;
  &.show{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.copyCheck{
  margin-bottom: 20px;
}
.copyCheckConf{
  margin-bottom: 20px;
  p{
    font-size: 1.5rem;
  }
}
}
.orderAcc{
  //display: none;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.confirmCol{
  p>span,p{
    //font-weight: bold;
    //padding-right: 1em;
    @include fz_vw(26);
  }
}
@media screen and (min-width: $break-point-middle) {
.confirmCol{
  p>span,p{
    font-size: 1.8rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.startDate{
  .col-date{
    margin-bottom: 20/750*100vw;
  }
  .col-tx{
    @include fz_vw(28);
  }
}
@media screen and (min-width: $break-point-middle) {
.startDate{
  >.inner{
    @include setTbl;
  }
  .col-date{
    width: 20em;
    margin-bottom: 0;
  }
  .col-tx{
    padding-left: 1em;
    font-size: 1.4rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#ui-datepicker-div{
  z-index: 2000!important;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
form.wpcf7-form{
	margin-top: -100px;
	padding-top: 100px;
}
div.wpcf7-response-output{
	border:none!important;
	background-color:#efefef!important;
	color:#333!important;
	text-align:center!important;
    margin-left:0!important;
    margin-right: 0!important;
}
//div.wpcf7-validation-errors, div.wpcf7-acceptance-missing{
div.wpcf7-response-output{
	border:none!important;
	background-color:rgba(212,0,3,.80)!important;
	color:$white!important;
	font-weight:bold!important;
}
.wpcf7 .text-banchi .wpcf7c-conf, .wpcf7 .text-city .wpcf7c-conf, .wpcf7 input.old.wpcf7c-conf, .wpcf7 select.wpcf7c-conf, .wpcf7 textarea.wpcf7c-conf{
	display:none;
}
.wpcf7 .wpcf7c-conf[type=text], .wpcf7 .wpcf7c-conf[type=email], .wpcf7 .wpcf7c-conf[type=tel], .wpcf7 textarea.wpcf7c-conf{
	background-color:transparent!important;
	cursor:default;
	border:none;
	&:focus{
		border:none;
	}
}
//.wpcf7-not-valid-tip{
//  position: absolute;
//  top: 3em;
//  .content &{
//    right: 0;
//    top: -2.5em;
//  }
//}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
	background-color:transparent!important;
}
@media screen and (min-width: $break-point-middle) {
//.wpcf7-not-valid-tip{
//  top: 2.5em;
//  .content &{
//    right: 0;
//    top: -2em;
//  }
//}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
