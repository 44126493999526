@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrapper {
  position: relative;
  overflow: hidden;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#container {
  padding-top: $header-size-sp;
}
@media screen and (min-width: $break-point-middle) {
  #container {
    padding-top: $header-size;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#homeContainer {
  //background-color: $white;
  //position: relative;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis {
  > .inner {
    position: relative;
    height: 1920/1080 * 100vw;
    overflow: hidden;
  }
  .movieSec {
    position: absolute;
    width: 100%;
    height: 100%;
    &::after {
      content: "";
      height: 100vh;
      width: 100vw;
      background-color: $white;
      position: absolute;
      top: 0;
      left: 0;
      @include transform-origin(100%, 0%);
      @include scale(1, 1);
      @include transition(1s $ease-out-sine);
      @include transition-delay(2s);
      .loaded & {
        @include scale(0, 1);
      }
    }
  }
  .dummy {
    height: 100%;
    > span {
      height: 100%;
      display: block;
      background-size: cover;
      background-position: 50% 50%;
      @include bgRatio;
    }
  }
  .contentSec {
    position: relative;
    height: 100%;
    @include setTbl;
  }
  h1 {
    > span {
      display: block;
      text-align: center;
    }
    svg {
      display: inline-block;
      fill: $key-color;
      .delayLoaded & {
        //opacity: 0!important;
        fill: $white;
      }
      .logoOut & {
        opacity: 0 !important;
      }
    }
    .tx {
      margin-bottom: 45 / $design-num-sp * 100vw;
    }
    .tx svg {
      width: 530 / $design-num-sp * 100vw;
      height: 60 / $design-num-sp * 100vw;
      position: relative;
      top: 20 / $design-num-sp * 100vw;
      opacity: 0;
      transition: 1s;
      .loaded & {
        top: 0;
        opacity: 1;
      }
    }
    .logo svg {
      width: 394 / $design-num-sp * 100vw;
      height: 62 / $design-num-sp * 100vw;
      position: relative;
      top: 20 / $design-num-sp * 100vw;
      opacity: 0;
      transition: 1s;
      //transition-delay: .4s;
      .logo2nd & {
        top: 0;
        opacity: 1 !important;
      }
      .logo2nd.delayLoaded & {
        //opacity: 0!important;
      }
      .logoOut & {
        opacity: 0 !important;
      }
    }
  }
  .scroller {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    opacity: 0;
    transition: 1s;
    .loaded & {
      opacity: 1;
    }
    a {
      color: $white;
      text-decoration: none;
      display: inline-block;
      position: relative;
      padding-bottom: 185 / $design-num-sp * 100vw;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -webkit-calc(50% - 1px);
        left: calc(50% - 1px);
        width: 2 / $design-num-sp * 100vw;
        height: 180 / $design-num-sp * 100vw;
        background-color: $white;
        animation: sdl 1.5s cubic-bezier(1, 0, 0, 1) infinite;
      }
      .tx {
        font-family: $fontMin;
        font-weight: bold;
        letter-spacing: 0.2em;
        @include fz_vw(18);
        line-height: 1em;
        position: relative;
        left: 2 / $design-num-sp * 100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  #mainVis {
    > .inner {
      height: 100vh;
      .setTablet & {
        height: 95vh;
      }
    }
    h1 {
      .tx {
        margin-bottom: 50 / $design-num * 100vw;
      }
      .tx svg {
        width: 861 / $design-num * 100vw;
        height: 97 / $design-num * 100vw;
        max-width: 861px;
        max-height: 97px;
      }
      .logo svg {
        width: 318 / $design-num * 100vw;
        height: 50 / $design-num * 100vw;
        max-width: 318px;
        max-height: 50px;
      }
    }
    .scroller {
      a {
        padding-bottom: 72px;
        &::after {
          width: 2px;
          height: 69px;
        }
        .tx {
          font-size: 1.8rem;
          left: 2px;
        }
      }
    }
  }
}
@media screen and (min-width: $design-width) {
  #mainVis {
    h1 {
      .tx {
        margin-bottom: 50px;
      }
    }
  }
}
/////////
@keyframes sdl {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .video-box {
    //position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    //height: 100vh;
    //height: 100vh;
    &.pcMov {
      display: none;
    }
    &.otherMov {
      display: block;
    }
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // .video {
  //   width: 100%;
  //   position: absolute;
  // }
}
@media screen and (min-width: $break-point-middle) {
  #mainVideo {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    .video-box {
      &.pcMov {
        display: block;
      }
      &.otherMov {
        display: none;
      }
    } // .setTablet &{
    //   display: none;
    //   height: 95vh;
    // }
    // @media (aspect-ratio: 16/9), (min-aspect-ratio: 16/9) {
    //   .video {
    //     top: 50%;
    //     transform: translateY(-50%);
    //   }
    // }
    // @media (max-aspect-ratio: 16/9) {
    //   .video {
    //     width: auto;
    //     height: 100%;
    //     left: 50%;
    //     transform: translateX(-50%);
    //   }
    // }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.workArchive {
  h3 {
    font-family: $fontMin;
    line-height: 1em;
    @include fz_vw(26);
    margin-bottom: 30 / $design-num-sp * 100vw;
    letter-spacing: 0.05em;
    color: $key-color;
    text-align: center;
  }
  .iconSec {
    > .inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      position: relative;
      &.style_02 {
        margin-top: 20 / $design-num-sp * 100vw;
      }
    }
  }
  article {
    text-align: center;
    width: 140 / $design-num-sp * 100vw;
    padding: 0 20 / $design-num-sp * 100vw;
    box-sizing: content-box;
    a {
      display: block;
      .icon {
        @include fz_vw(140);
        line-height: 1em;
        color: $key-color;
        margin-bottom: 10 / $design-num-sp * 100vw;
      }
      h2 {
        color: $black;
        line-height: (26/20) + em;
        @include fz_vw(20);
        font-weight: normal;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .workArchive {
    h3 {
      font-size: 2.4rem;
      margin-bottom: 50px;
    }
    .iconSec {
      > .inner {
        justify-content: space-between;
        &.style_01 {
          max-width: 892px;
        }
        &.style_02 {
          max-width: 430px;
          margin-top: -110px;
        }
      }
    }
    article {
      width: 160 / $design-num * 100vw;
      padding: 0;
      a {
        .icon {
          @include fz_vwpc(160);
          margin-bottom: 20px;
        }
        h2 {
          line-height: (26/18) + em;
          font-size: 1.8rem;
          transition: 0.3s;
        }
      }
      a:hover {
        opacity: 0.9;
        h2 {
          color: $hover-color;
        }
      }
    }
  }
}
@media screen and (min-width: 893px) {
  .workArchive {
    .iconSec {
      > .inner {
        &.style_02 {
          max-width: 512px;
        }
      }
    }
  }
}
@media screen and (min-width: $content-width) {
  .workArchive {
    article {
      width: 160px;
      a {
        .icon {
          font-size: 16rem;
        }
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.gmapSec {
  position: relative;
  margin-top: 40 / $design-num-sp * 100vw;
}
@media screen and (min-width: $break-point-middle) {
  .gmapSec {
    position: relative;
    margin-top: 60px;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#instaWrap {
  padding-left: 115 / $design-num-sp * 100vw;
  padding-right: 115 / $design-num-sp * 100vw;
}
@media screen and (min-width: $break-point-middle) {
  #instaWrap {
    @include contentWrap;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.instaCol {
  .carouselItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .carouselItem > * {
    width: 194 / $design-num-sp * 100vw;
    padding-top: 194 / $design-num-sp * 100vw;
    overflow: hidden;
    margin-right: 20 / $design-num-sp * 100vw;
    position: relative;
    &:nth-child(n + 3) {
      margin-top: 20 / $design-num-sp * 100vw;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    > span {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
    a {
      position: relative;
      z-index: 100;
      display: block;
      width: 100%;
      height: 100%;
      @include transition(0.3s);
      img {
        @include imgset;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        @include transition(0.8s);
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .instaCol {
    .carouselItem {
      &::after,
      &::before {
        content: "";
        display: block;
        width: ($content-num-base - 20 * 3)/4 / $content-num-base * 100%;
        height: 0;
      }
      &::before {
        order: 1;
      }
    }
    .carouselItem > * {
      width: ($content-num-base - 20 * 3)/4 / $content-num-base * 100%;
      padding-top: ($content-num-base - 20 * 3)/4 / $content-num-base * 100%;
      margin-right: 20 / $content-num-base * 100%;
      &:nth-child(n + 3) {
        margin-top: 0;
      }
      &:nth-child(n + 5) {
        margin-top: 20px;
      }
      &:nth-child(2n) {
        margin-right: 20 / $content-num-base * 100%;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      > span {
      }
      a {
        &:hover {
          @include opacity(0.8);
          img {
            @include transform-origin(50%, 50%);
            @include scale(1.2);
          }
        }
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sign {
  margin-top: 30 / $design-num-sp * 100vw;
  line-height: 1em;
  @include fz_vw(36);
  letter-spacing: -0.01em;
  span {
    @include fz_vw(26);
    line-height: 1em;
    padding-right: 1em;
  }
}
@media screen and (min-width: $break-point-middle) {
  .sign {
    margin-top: 30px;
    font-size: 3rem;
    span {
      font-size: 2rem;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.footGallery {
  img {
    @include imgset;
  }
  .infiniteItem {
    width: 128 / $design-num-sp * 100vw;
  }
  > .infiniteslide > .infiniteItem:nth-child(n + 2) {
    display: none;
  }
}
@media screen and (min-width: $break-point-middle) {
  .footGallery {
    .infiniteItem {
      width: 256 / $design-num * 100vw;
    }
  }
}
@media screen and (min-width: $content-width) {
  .footGallery {
    .infiniteItem {
      width: 256px;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.objStyle {
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &.style_01 {
    &::before {
      top: 100 / $design-num-sp * 100vw;
      border-width: 205 / $design-num-sp * 100vw 0vw 205 / $design-num-sp *
        100vw 100vw;
      border-color: transparent transparent transparent $key-gray1;
    }
  }
  &.style_02 {
    &::before {
      top: 260 / $design-num-sp * 100vw;
      border-width: 210 / $design-num-sp * 100vw 0vw 210 / $design-num-sp *
        100vw 100vw;
      border-color: transparent transparent transparent $key-gray1;
    }
  }
  &.style_03 {
    &::before {
      top: 400 / $design-num-sp * 100vw;
      border-width: 165 / $design-num-sp * 100vw 100vw 165 / $design-num-sp *
        100vw 0;
      border-color: transparent $key-gray1 transparent transparent;
    }
  }
  &.style_04,
  &.style_06,
  &.style_08,
  &.style_10 {
    &::before {
      top: 100 / $design-num-sp * 100vw;
      border-width: 275 / $design-num-sp * 100vw 100vw 275 / $design-num-sp *
        100vw 0vw;
      border-color: transparent $key-gray1 transparent transparent;
    }
  }
  &.style_05,
  &.style_07,
  &.style_09,
  &.style_11 {
    &::before {
      top: 900 / $design-num-sp * 100vw;
      border-width: 275 / $design-num-sp * 100vw 0vw 275 / $design-num-sp *
        100vw 100vw;
      border-color: transparent transparent transparent $key-gray1;
    }
  }
  &.style_06 {
    &::before {
      top: 1700 / $design-num-sp * 100vw;
    }
  }
  &.style_07 {
    &::before {
      top: 2500 / $design-num-sp * 100vw;
    }
  }
  &.style_08 {
    &::before {
      top: 3300 / $design-num-sp * 100vw;
    }
  }
  &.style_09 {
    &::before {
      top: 4100 / $design-num-sp * 100vw;
    }
  }
  &.style_10 {
    &::before {
      top: 4900 / $design-num-sp * 100vw;
    }
  }
  &.style_11 {
    &::before {
      top: 5700 / $design-num-sp * 100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .objStyle {
    &.style_01 {
      &::before {
        top: 195 / $design-num * 100vw;
        border-width: 345 / $design-num * 100vw 100vw 345 / $design-num * 100vw
          0;
        border-color: transparent $key-gray1 transparent transparent;
      }
    }
    &.style_02 {
      &::before {
        top: 200 / $design-num * 100vw;
        border-width: 500 / $design-num * 100vw 0vw 500 / $design-num * 100vw
          100vw;
      }
    }
    &.style_03 {
      &::before {
        display: none;
      }
    }
    &.style_04 {
      &::before {
        top: 500 / $design-num * 100vw;
        border-width: 345 / $design-num * 100vw 100vw 345 / $design-num * 100vw
          0;
        border-color: transparent $key-gray1 transparent transparent;
      }
    }
    &.style_05 {
      &::before {
        top: 1400 / $design-num * 100vw;
        border-width: 345 / $design-num * 100vw 0vw 345 / $design-num * 100vw
          100vw;
        border-color: transparent transparent transparent $key-gray1;
      }
    }
  }
}
@media screen and (min-width: $content-width-base) {
  .objStyle {
    &.style_01 {
      &::before {
        top: 195px;
        border-width: 345px 100vw 345px 0;
      }
    }
    &.style_02 {
      &::before {
        top: 200px;
        border-width: 500px 0vw 500px 100vw;
      }
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.objWrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
////////////////////////////////////////////////////////////
.bgObjStyle {
  $baseSepSp: 410;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    top: 100 / $design-num-sp * 100vw;
    border-width: 165 / $design-num-sp * 100vw 100vw 165 / $design-num-sp *
      100vw 0vw;
    border-color: transparent $key-gray1 transparent transparent;
  }
  &:nth-child(2n)::before {
    border-width: 165 / $design-num-sp * 100vw 0vw 165 / $design-num-sp * 100vw
      100vw;
    border-color: transparent transparent transparent $key-gray1;
  }
  @for $i from 1 through 13 {
    &:nth-child(#{$i})::before {
      top: ($baseSepSp * $i + 330 * ($i - 1)) / $design-num-sp * 100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .bgObjStyle {
    $baseSep: 480;
    &::before {
      top: $baseSep/$design-num * 100vw;
      border-width: 400 / $design-num * 100vw 100vw 400 / $design-num * 100vw 0;
    }
    &:nth-child(2n)::before {
      border-width: 400 / $design-num * 100vw 0vw 400 / $design-num * 100vw
        100vw;
      border-color: transparent transparent transparent $key-gray1;
    }
    @for $i from 1 through 6 {
      &:nth-child(#{$i})::before {
        top: ($baseSep * $i + 800 * ($i - 1)) / $design-num * 100vw;
      }
    }
  }
}
@media screen and (min-width: $content-width-base) {
  .bgObjStyle {
    &::before {
      border-width: 345px 100vw 345px 0;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tagArchive {
  border: 2 / $design-num-sp * 100vw solid $key-color;
  background-color: $white;
}
@media screen and (min-width: $break-point-middle) {
  .tagArchive {
    border: 2px solid $key-color;
  }
}
//////////////////////////
.tagNav {
  ul {
    @include setTbl;
  }
  li {
    border-right: 1px solid $key-color;
    &:last-child {
      border-right: none;
    }
    a {
      white-space: nowrap;
      display: block;
      padding: 11 / $design-num-sp * 100vw 5 / $design-num-sp * 100vw;
      text-decoration: none;
      color: $key-color;
      @include fz_vw(17);
      line-height: 1em;
      text-align: center;
      vertical-align: bottom;
      font-weight: 600;
      font-family: $fontMin;
      > span > span {
        @include fz_vw(26);
        line-height: 1em;
      }
    }
    &.active a {
      cursor: default;
      pointer-events: none;
      background-color: $key-color;
      color: $white;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .tagNav {
    li {
      border-left: 1px solid $key-color;
      &:first-child {
        border-left: none;
      }
      a {
        padding: 14px 3px;
        font-size: 2rem;
        > span > span {
          font-size: 2.5rem;
        }
      }
      a:hover {
        color: $white;
        background-color: $key-green;
      }
    }
  }
}
//////////////////////////
.tagCont {
  > .inner {
    padding: 40 / $design-num-sp * 100vw 28 / $design-num-sp * 100vw;
  }
  article {
    border-bottom: 1px solid #959595;
    padding: 24 / $design-num-sp * 100vw 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
    }
  }
  h2 {
    font-family: $fontMin;
    font-weight: 600;
    line-height: 1em;
    @include fz_vw(30);
    margin-bottom: 4 / $design-num-sp * 100vw;
    color: $key-color;
  }
  dl {
    line-height: (42/26) + em;
    @include fz_vw(26);
    > * {
      display: inline;
    }
  }
  dt {
    &::after {
      content: "：";
    }
  }
}
@media screen and (min-width: $break-point-middle) {
  .tagCont {
    > .inner {
      padding: 35 / $design-num * 100vw 98 / $design-num * 100vw;
    }
    article {
      border-bottom: 2px solid #959595;
      padding: 18px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border: none;
      }
    }
    h2 {
      font-size: 2.3rem;
      margin-bottom: 2px;
    }
    dl {
      line-height: (30/18) + em;
      font-size: 1.8rem;
    }
  }
}
@media screen and (min-width: $content-width-base) {
  .tagCont {
    > .inner {
      padding: 35px 98px;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.workGraph {
  > .inner {
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
  $delayspeed: 0.1;
  .workGraph {
    .graph {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      //justify-content: space-between;
      align-items: baseline;
      li {
        position: relative;
        img {
          position: relative;
          bottom: -500px;
          transition: 0.6s ease-out;
          border-radius: 22px 0 0 0;
        }
      }
      &.active li img {
        bottom: 0;
      }
      li:nth-child(2) {
        img {
          transition-delay: $delayspeed + s;
        }
      }
      li:nth-child(3) {
        img {
          transition-delay: $delayspeed * 2 + s;
        }
      }
      li:nth-child(4) {
        img {
          transition-delay: $delayspeed * 3 + s;
        }
      }
      li:nth-child(5) {
        img {
          transition-delay: $delayspeed * 4 + s;
        }
      }
      li:nth-child(6) {
        img {
          transition-delay: $delayspeed * 5 + s;
        }
      }
    }
    .icon {
      position: absolute;
      bottom: 15%;
      left: 12%;
      opacity: 0;
      transition: 1s;
      transition-delay: 1s;
      width: 83 / $design-num * 100vw;
      img {
        @include imgset;
      }
      .active & {
        opacity: 1;
      }
    }
  }
}
@media screen and (min-width: $design-width) {
  .workGraph {
    .icon {
      width: 83px;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#toTop {
  //display: none;
  position: fixed;
  bottom: 50/750 * 100vw;
  right: 40/750 * 100vw;
  @include fz_vw(18);
  line-height: 1em;
  //font-family: $fontEn;
  text-align: center;
  @include opacity(0);
  @include transition(0.3s);
  z-index: 2100;
  //padding: 10/750*100vw;
  //@include basicShadow;
  a {
    @include border-radius(3px);
    color: $white;
    text-decoration: none;
    background-color: $black;
    padding: 10/375 * 100vw 12/375 * 100vw;
    display: block;
    &:before {
      display: block;
      margin-bottom: 5px;
      @include iconFont;
      content: "\EA14";
      @include fz_vw(24);
      top: 0;
      position: relative;
      @include transition(0.3s);
      line-height: 1em;
    }
  }
  &.active {
    @include opacity(1);
  }
}
@media screen and (min-width: $break-point-middle) {
  #toTop {
    display: block;
    font-size: 13px;
    bottom: 120px;
    right: 20px;
    a {
      padding: 10px 16px;
      text-align: center;
      &:before {
        font-size: 20px;
      }
      &:hover {
        background-color: lighten($black, 20%);
        &:before {
          top: 3px;
        }
      }
    }
    &.footPos {
      a {
        color: $white;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  #toTop {
    bottom: 20px;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (max-width: 768px) {
  .pal {
    transform: none !important;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ginouTab {
  display: none;
  &.active {
    display: block;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
